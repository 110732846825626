$white: #fff;
$gray-100: #f0f3f6;
$gray-200: #e9ecef;
$gray-300: #dee0e5;
$gray-400: #abb0b7;
$gray-500: #8c8e91;
$gray-600: #6c757d;
$gray-700: #4f5155;
$gray-800: #2e2f31;
$gray-400-20: #abb0b733;
$black: #000;

$grays: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-400-20": $gray-400-20,
);

$blue-50: #d3eefa;
$blue-100: #5ccdff;
$blue-300: #25ace7;
$blue-400: #138abe;
$blue-600: #004395;
$blue-800: #00375f;
$blue-300-20: #25ace733;

$blues: (
  "blue-50": $blue-50,
  "blue-100": $blue-100,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-600": $blue-600,
  "blue-800": $blue-800,
  "blue-300-20": $blue-300-20,
);

$red: #f22018;
$orange: #fa9300;
$yellow: #fff230;
$green: #1ad353;
$gray: $gray-400;

$primary: $blue-600;
$secondary: $gray-600;
$success: $green;
$info: $blue-300;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$custom-colors: (
  "gray-300": $gray-300,
);

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue-600,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);
