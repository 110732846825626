@import "../../../assets/stylesheets/bootstrap/config/grid";
@import "../../../assets/stylesheets/bootstrap/config/colorsVariables";
@import "~bootstrap/scss/mixins/breakpoints";

.progressBar {
  height: 0.25rem;
  background-color: #d3eefa;
  margin-top: 1px;
}

.retryButton {
  color: $gray-600;

  &:hover {
    color: $gray-800;
  }
}

.progressBarWrapper {
  bottom: var(--bottom);
  right: 100px;
  width: 22rem;
  height: 2.875rem;
  z-index: 9999;

  &Error {
    background-color: #faeeee
  }

  &Done {
    background-color: #eff7ee;
  }
}

.successBar {
  color: #57874d;
}